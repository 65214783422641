import React from 'react'
import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'
import HeaderGroup from '@/views/Discover/Activations/v2/ActivationsTable/HeaderGroup'
import InsertionOrderGroupRow from '@/views/Discover/Activations/v2/ActivationsTable/InsertionOrderGroupRow'
import ActivationRow from '@/views/Discover/Activations/v2/ActivationsTable/ActivationRow'

type ActivationsTableProps = {
  selectedActivationIds: number[]
  allMomentsOnPageAreSelected: boolean
  setSelectedActivationIds: (value: React.SetStateAction<number[]>) => void
  activationsList: Array<ActivationItemType>
  showTrashcan?: boolean,
  itemsSelectable?: boolean,
  handleDelete: (activationItemId: number) => void
  handleSelect: (checked: boolean, activationItem: ActivationItemType) => void
  handleSelectAll: (checked: boolean) => void
  handleDeleteActivationsGroupedByIo: (activationsToBeDeleted: number[]) => void
}

const ActivationsTable = ({
  selectedActivationIds,
  setSelectedActivationIds,
  activationsList,
  allMomentsOnPageAreSelected,
  showTrashcan = true,
  itemsSelectable = true,
  handleDelete,
  handleDeleteActivationsGroupedByIo,
  handleSelect,
  handleSelectAll
}: ActivationsTableProps) => {
  const activationsGroupedByInsertionOrder = React.useMemo(
    () => groupByInsertionOrder(activationsList),
    [activationsList]
  )
  const defaultExpandedInsertionOrder =
    activationsGroupedByInsertionOrder.size > 0
      ? new Set([Array.from(activationsGroupedByInsertionOrder.keys())[0]])
      : null
  const [expandedInsertionOrder, setExpandedInsertionOrder] = React.useState<Set<number> | null>(
    defaultExpandedInsertionOrder
  )

  return (
    <div className="relative my-3 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="w-full overflow-y-auto border-collapse divide-y divide-gray-300 table-fixed">
        <HeaderGroup {...{ activationsList, allMomentsOnPageAreSelected, handleSelectAll, selectedActivationIds, showTrashcan, itemsSelectable }} />
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from(activationsGroupedByInsertionOrder.entries()).map(([insertionOrderId, activations]) => (
            <tr key={insertionOrderId}>
              <td colSpan={6}>
                <table
                  className="w-full table-fixed"
                  data-testid="insertion-order-group-row"
                >
                  <TableColGroup />
                  <tbody>
                    <InsertionOrderGroupRow
                      {...{
                        activations,
                        expandedInsertionOrder,
                        handleDeleteActivationsGroupedByIo,
                        insertionOrderId,
                        selectedActivationIds,
                        setExpandedInsertionOrder,
                        setSelectedActivationIds,
                        key: insertionOrderId,
                        showTrashcan,
                        itemsSelectable
                      }}
                    />
                    {expandedInsertionOrder?.has(insertionOrderId) && (
                      <tr
                        data-testid="activation-row-wrapper"
                        key={insertionOrderId + 'activation-wrapper'}
                      >
                        <td colSpan={6}>
                          <table
                            className="w-full table-fixed"
                            data-testid="activation-row-table"
                          >
                            <TableColGroup />
                            <tbody>
                              {activations?.map((activationItem) => (
                                <ActivationRow
                                  {...{
                                    activationItem,
                                    handleDelete,
                                    showTrashcan,
                                    itemsSelectable,
                                    handleSelect,
                                    selectedActivationIds,
                                    key: activationItem.activationItemId
                                  }}
                                />
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const groupByInsertionOrder = (activationsList: Array<ActivationItemType>) => {
  //Fetch the ioIds and keep them sorted
  const insertionOrderIds = activationsList.map(({insertionOrderId})=> insertionOrderId).sort()
  //use the sorted ioId key list to create a consistent Map: this is to avoid unwanted shuffling if activationsList changes.
  const activationsGroupedByIO = new Map<number, Array<ActivationItemType>>(insertionOrderIds.map((ioId)=>[ioId,[]]))

  for (const activation of activationsList) {
    const activationGroup = activationsGroupedByIO.get(activation.insertionOrderId)
    activationGroup?.push(activation)
  }
  return activationsGroupedByIO
}

const TableColGroup = () => (
  <colgroup>
    <col className="relative w-16 px-4" />
    <col className="py-4 pr-3" />
    <col className="px-3 py-3 w-28 " />
    <col className="px-3 py-3" />
    <col className="px-3 py-3 w-28" />
    <col className="relative w-12 py-3 pl-3 pr-4 sm:pr-6" />
  </colgroup>
)

export default ActivationsTable
