import { useQuery } from "@tanstack/react-query"
import { logError } from "@/utils"
import { api } from "@/api/api"
import { IPlatform } from "@/views/TikTok/Campaigns/Campaigns"
import { useState } from "react"
import { IStatusOption } from "./Hashtags/types"

const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function useTikTok(authCode: string) {
  const tikTokAccessToken = useQuery(
    ['access-token', authCode],
    () => api.tikTok.getAccessToken(authCode),
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting TikTok access token.' })
      }
    }
  )

  return {
    tikTokAccessToken
  }
}

export function useCampaigns(minCreatedDate: Date, importedCampaignsPage: number, importedCampaignsPageSize: number,
  notImportedCampaignsPage: number, notImportedCampaignsPageSize: number,
  operationStatus?: string, search?: string, notImportedSearch?: string, selectedPlatform?: IPlatform, accountId?: number) {

  const advertisersQuery = useQuery(
    ['advertisers', accountId],
    async () => {
      return await api.tikTok.getAdvertisers(accountId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in advertisers query' })
      }
    }
  )

  const importedCampaignsQuery = useQuery(
    ['importedCampaigns', accountId, minCreatedDate, operationStatus, importedCampaignsPage, importedCampaignsPageSize, search],
    async () => {
      return await api.tikTok.getImportedCampaigns(formatDate(minCreatedDate), importedCampaignsPage, importedCampaignsPageSize,
        operationStatus, search, accountId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in imported campaigns query' })
      },
      enabled: advertisersQuery.isSuccess
    }
  )

  const notImportedCampaignsQuery = useQuery(
    ['notImportedCampaigns', accountId, notImportedCampaignsPage, notImportedCampaignsPageSize, notImportedSearch, selectedPlatform],
    async () => {
      return await api.tikTok.getNotImportedCampaigns(notImportedCampaignsPage, notImportedCampaignsPageSize, accountId, notImportedSearch, selectedPlatform)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in not imported campaigns query' })
      },
      enabled: advertisersQuery.isSuccess
    }
  )

  return {
    importedCampaignsQuery,
    notImportedCampaignsQuery,
    advertisersQuery
  }
}

export function useHashtags(category: string[], date: string, status: IStatusOption[], sortBy: string, page: number, itemsPerPage: number, accountId?: number) {
  const hashtagsQuery = useQuery(
    ['hashtags', category, date, status, sortBy, page, itemsPerPage, accountId],
    async () => {
      const offset = page * itemsPerPage
      return await api.tikTok.getHashtags(category, date, status, sortBy, itemsPerPage, offset, accountId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in hashtags query' })
      }
    }
  )

  return {
    hashtagsQuery
  }
}

export function useHashtag(hashtagId: string, dateRange: string) {
  const hashtagAudienceQuery = useQuery(
    ['hashtagAudience', hashtagId, dateRange],
    async () => {
      return await api.tikTok.getHashtagAudience(hashtagId, dateRange)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in hashtag audience query' })
      }
    }
  )
  return {
    hashtagAudienceQuery
  }
}

export function useHashtagsStatus(hashtagsIds: string[], accountId?: number) {
  const hashtagsStatusQuery = useQuery(
    ['hashtagsStatus', hashtagsIds, accountId],
    async () => {
      return await api.tikTok.getHashtagsStatus(hashtagsIds, accountId)
    },
    {
      retry: 1,
      cacheTime: 0,
      enabled: hashtagsIds.length > 0,
      onError: (err) => {
        logError(err, { info: 'onError in hashtags status query' })
      }
    }
  )

  return {
    hashtagsStatusQuery
  }
}


export function useTargetStatus(accountId: number, onlyAccessTokenCheck:boolean=false) {

  const authenticationStatusQuery = useQuery(
    ['authenticationStatus'],
    async () => {
      const respAuthentication = await api.tikTok.status(accountId);
      if (respAuthentication?.data && respAuthentication.data.status == 'ACCESS_TOKEN_VALID') {
        const respCampaign = await api.tikTok.getImportedCampaignStatus(accountId);
        if (respCampaign && respCampaign.total > 0 || onlyAccessTokenCheck) {
          return {
            error: false
          }
        } else {
          return {
            source: 'campaign',
            error: true,
          };

        }
      } else {
        return {
          source: 'authentication',
          error: true,
        };
      }
    },
    {
      retry: 0,
      cacheTime: 0,
      staleTime: 0,
      onError: (err: any) => {
        logError(err, { info: 'onError on getting authentication status' })
      }
    }
  )

  return {
    authenticationStatusQuery
  }
}

export function useActivations(status: string, accountId: number, search?: string, campaign?: number, adGroup?: number, hashtagStatus?: string, minCreatedDate?: Date) {
  const tikTokActivationsQuery = useQuery(
    ['tikTokActivations', status, accountId, search, campaign, adGroup, hashtagStatus, minCreatedDate],
    async () => {
      const dateString = minCreatedDate ? formatDate(minCreatedDate) : undefined
      return await api.tikTok.getActivations(status, accountId, search, campaign, adGroup, hashtagStatus, dateString)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in activations query' })
      },
      enabled: !!accountId

    }
  )
  return {
    tikTokActivationsQuery
  }
}

export function useCampaignsAdGroupsHierarchy( accountId: number ) {
  return useQuery(
    ['campaignsAdGroupsHierarchy', accountId ],
    () => api.tikTok.getCampaignsAdGroupsHierarchy( accountId ) ,
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in getCampaignsAdGroupsHierarchy query' })
      },
      enabled: !!accountId
    }
  )
}


